import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [show, setShow] = useState(false);
    const [collapse, setCollapse] = useState(false);

    const toggleSidebar = () => setCollapse(!collapse);
    const toggleMobileSidebar = () => setShow(!show);

    return (
        <SidebarContext.Provider
            value={{ show, collapse, toggleSidebar, toggleMobileSidebar }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => useContext(SidebarContext);
