import { combineReducers, createStore, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import {postByIdRequest, queryPageRequest, postFormDataByIdRequest, loginReducer, signupReducer, getRequest, getByIdRequest, postFormDataRequest, postRequest, queryPageByIdRequest } from "./redux/reducers";


const reducer = combineReducers({

  // Auth 
  login: loginReducer,
  signup: signupReducer,

  //Global
  cityList: getRequest('global/cities'),
  townList: getByIdRequest('global/districts'),
  businessCategoryList: getRequest('global/categories'),
  activityCategoryList: getRequest('global/activity_categories'),

  //Place
  placeCreate: postFormDataRequest('places/create'),
  placeDetail: getByIdRequest('places'),
  placeCreateTable: postRequest('places/create/table'),
  placeCreateTableAvailability: postRequest('places/create/reservation/tableavailability'),
  placeTables: getByIdRequest('places/tables'),
  placeReservationsQueryPageList: queryPageByIdRequest('reservation'),
  placeCategories: getByIdRequest('places/categories'),
  placeEdit: postFormDataByIdRequest('places/edit'),
  placeActivitiesQueryPageList: queryPageByIdRequest('activity/place'),
  placeCreateWorkinghour: postRequest('places/create_opening_hours'),
  placeOpeningHour: getByIdRequest('places/opening_hours'),
  tableAvailabilityQueryPageList: queryPageRequest('places/tables/available/date'),
  tableAvailabilityDelete: postRequest('places/delete/reservation/single/tableavailability'),
  updatePlaceTable: postRequest('places/update/table'),
  deleteTable: postRequest('places/delete/table'),
  reservationDetailInfo: getByIdRequest('auth/user/reservations'),
  campaignData: getByIdRequest('places/campaign'),
  campaignCreate: postFormDataRequest('places/campaign/create'),
  campaignUpdate: postByIdRequest('places/campaign/update'),
  //Activity
  activityDetail : getByIdRequest('activity'),
  activityCreate: postFormDataRequest('activity/create'),
  activityTicketCreate: postRequest('activity/create/tickets'),
  activityTicketStatisticQueryPageList: queryPageByIdRequest('statistic/activities/ticket'),
  activityTicketStatisticAll: getByIdRequest('statistic/activities/ticket'),
  placeActivitiesTicketStatisticQueryPageList: queryPageRequest('statistic/place/activities/ticket'),
  activityTicketControl: postByIdRequest('ticket/access/control'),
  activityDelete: postByIdRequest('activity/delete'),
  
  //Commnets
  placeComments: getByIdRequest('places/comments'),


  //Organization
  organizationActivitiesQueryPageList: queryPageByIdRequest('activity/organization'),
  organizationCreate: postFormDataRequest('organization/create'),
  organizationDetail: getByIdRequest('organization'),
  activityTicketStatisticOrgQueryPageList: queryPageByIdRequest('statistic/activities/organization/ticket'),
  activityTicketStatisticAllOrg: getByIdRequest('statistic/activities/organization/all/ticket'),
  organizationEdit: postFormDataByIdRequest('organization/edit')
});





const store = createStore(reducer, applyMiddleware(thunk));

export default store;
