const initialState = {
  success: false,
  error: false,
};

const login =  function (state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST': {
      return {...initialState, loading: true};
    }
    case 'LOGIN_SUCCESS': {
      return {...state, success: true, loading: false};
    }
    case 'LOGIN_FAILURE': {
      return {...state, error: true, loading: false};
    }
    case 'LOGOUT' : {
      return Object.assign({}, state);
    }
    default:
      return state;
  }
}

export default login