// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './translations';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
const helmetContext = {};
root.render(
  <React.Fragment>
    <HelmetProvider context={helmetContext}>
    <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
 </React.StrictMode>
    </HelmetProvider>

  </React.Fragment>
 
);