const initialState = {
  data: [],
  loading: false,
  success: false,
  error: false,
};

export const postByIdRequest = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/POST_BY_ID_REQUEST_REQUEST`: {
      return { ...initialState, loading: true };
    }
    case `${namespace}/POST_BY_ID_REQUEST_SUCCESS`: {
      return { ...state, data: action.data, loading: false, success: true };
    }
    case `${namespace}/POST_BY_ID_REQUEST_FAILURE`: {
      return { ...state, data: action.error, loading: false, success: false, error: true };
    }
    default:
      return state;
  }
};
