import React, { Suspense, useEffect, useState } from 'react'
import Routers from './router'
import './App.scss'
import { SidebarProvider } from './context/SidebarContext'
import { Provider } from 'react-redux';
import store from './store'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalBody } from 'reactstrap';

function App() {
    const [alertModalOpen, setAlertModalOpen] = useState(false)


    useEffect(() => {
      const alertModalReaded = localStorage.getItem('alert');
      if(alertModalReaded !== 'true'){
        setAlertModalOpen(true)

      }
    }, [])

    const handleCloseAlertModal = ()=> {
      localStorage.setItem('alert', true);
      setAlertModalOpen(false)
    } 

    const Loader = () => {
      return (
          // <div style={{ position: 'absolute', top: '50%', bottom: '50%', right: '50%', left: '50%' }}>
          //   <div class="loader"></div>
          // </div>
          <div className="preloader-wrapper" >
          <div className="preloader">
            <div className="spinner">
              <div className="spin spinner"></div>
            </div>
          </div>
        </div>
      );
    };

  return (
   <React.Fragment>
    <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    <SidebarProvider>
    <Provider store={store}>
    <Suspense fallback={Loader()}>
       <Routers />
     </Suspense>
    </Provider>
    </SidebarProvider>

    <Modal
        isOpen={alertModalOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        size="md"
      >
        <ModalBody>

          <div className="d-flex justify-content-between "  >
            <div></div>
            <span className='font-weight-600 font-size-20'>Biletli Etkinlik Bilgilendirmesi </span>

            <div className='close-icon cursor-pointer' id="close-button" onClick={() => handleCloseAlertModal(false)}>
              <i className='fas fa-close font-size-24 text-dark minus-button minus-md' />
            </div>
          </div>
          <div className='d-flex justify-content-center my-4'>
            <div className='bg-pink-light border-radius-full d-flex justify-content-center align-items-center' style={{width:'75px', height: '75px'}}>
              <i className='fas fa-close text-pink font-size-40' />
            </div>
          </div>
            Şu anda biletli etkinlikleri destekleyemiyoruz. İşletmenizin ücretsiz etkinliklerini Sehrimbu kullanıcılarıyla paylaşabilir, işletmeniz için rezervasyon alabilir, yeni müşteriler kazanabilirsiniz.
        </ModalBody>
      </Modal>
   </React.Fragment>
  )
}

export default App